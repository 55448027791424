'use client'

/* eslint-disable */
import React, { ReactNode, useState } from 'react'
import { useEffect } from 'react'

interface RcInlineEditProps<T> {
  canEdit: boolean
  Component: React.ComponentType<T> & RcCompletable
  ComponentProps: T
  showUnset?: boolean
  ctrlRequired?: boolean
}

export interface RcCompletable {
  onComplete?: () => void
}

export const RcInlineEdit = <T extends Record<string, any>>({
  Component,
  ComponentProps,
  children,
  canEdit,
  ctrlRequired,
  showUnset = true
}: RcInlineEditProps<T> & { children?: ReactNode }) => {
  const [ctrlDown, setCtrlDown] = useState(false)
  const [hovering, setHovering] = useState(false)
  const [editing, setEditing] = useState(false)

  useEffect(() => {
    if (!canEdit) return
    const handle = (e: KeyboardEvent) => {
      setCtrlDown(e.altKey)
    }
    document.addEventListener('keydown', handle, false)
    document.addEventListener('keyup', handle, false)

    return () => {
      document.removeEventListener('keydown', handle, false)
      document.removeEventListener('keyup', handle, false)
    }
  }, [canEdit])

  if (!canEdit) {
    return <>{children}</>
  }

  if (!editing) {
    const { source, property } = ComponentProps

    const showPlaceholder =
      showUnset && source && !source?.[property]?.trim() && ctrlDown

    return (
      <span
        tabIndex={-1}
        onMouseEnter={evt => {
          if (evt.altKey) {
            setHovering(true)
          }
        }}
        onMouseLeave={evt => setHovering(false)}
        onClick={evt => {
          if (evt.altKey) {
            setEditing(true)
            setHovering(false)
            evt.preventDefault()
            evt.stopPropagation()
          }
        }}
        style={{
          cursor: canEdit && hovering ? 'pointer' : 'inherit',
          fontStyle: showPlaceholder ? 'italic' : 'normal',
          background: hovering ? 'gray' : ''
        }}
      >
        <>{showPlaceholder ? `${property} unset` : children}</>
      </span>
    )
  }

  return (
    <Component
      {...ComponentProps}
      onComplete={() => {
        setEditing(false)
      }}
    />
  )
}
