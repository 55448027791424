import Card from '@mui/material/Card'
import { CoreDocument } from '@rallycry/api-suite-typescript/dist/models/CoreDocument'
import { MatchDetailDisplay } from '../../Competition/components/match/MatchDetailDisplay'
import { expandById, ExpansionType } from '@/core/expand'

export const MatchCoreDocRow = ({ coreDoc }: { coreDoc: CoreDocument }) => {
  const {
    competition,
    event,
    competitionMatch: match,
    competitionEntries: teams
  } = coreDoc

  const team1 = teams?.[0]
  const team2 = teams?.[1]
  const community1 = expandById(
    team1?.representing,
    coreDoc,
    ExpansionType.Communities
  )
  const community2 = expandById(
    team2?.representing,
    coreDoc,
    ExpansionType.Communities
  )

  return (
    <Card elevation={3}>
      <MatchDetailDisplay
        competition={competition}
        extraContext
        event={event}
        match={match}
        team1={team1}
        team2={team2}
        community1={community1}
        community2={community2}
        score1={match?.scores?.[0]}
        score2={match?.scores?.[1]}
      />
    </Card>
  )
}
