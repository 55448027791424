import FormLabel from '@mui/material/FormLabel'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import { SwitchProps } from '@mui/material/Switch'
import { useFormikContext } from 'formik'
import { get } from 'lodash-es'
import Tooltip from '@mui/material/Tooltip'
import { RcIcon } from '@/components/atoms/RcIcon'

export const LabeledSwitch: React.FC<
  SwitchProps & {
    label?: React.ReactNode
    direction?: 'row' | 'row-reverse'
    info?: React.ReactNode
  }
> = ({ checked, label, info, direction = 'row', ...props }) => {
  const { values, setFieldValue } = useFormikContext<any>()

  return (
    <Stack direction={direction} spacing={2} alignItems='center'>
      <Switch
        id={props.name}
        checked={checked || get(values, props.name || '', false)}
        onClick={() =>
          props.name
            ? setFieldValue?.(props.name, !get(values, props.name || '', false))
            : undefined
        }
        {...props}
        icon={<RcIcon icon={['fal', 'times-circle']} color='error' />}
        checkedIcon={<RcIcon icon={['fal', 'check-circle']} color='success' />}
      />
      {label ? (
        <FormLabel
          htmlFor={props.name}
          onClick={() =>
            props.name
              ? setFieldValue(props.name, !get(values, props.name || '', false))
              : undefined
          }
          sx={{
            cursor: 'pointer',
            userSelect: 'none',
            paddingBottom: 1,
            textTransform: 'uppercase',
            fontSize: '0.875rem'
          }}
        >
          {label}
        </FormLabel>
      ) : null}
      {info ? (
        <Tooltip enterDelay={0} arrow placement='top' title={info}>
          <span style={{ marginBottom: 6 }}>
            <RcIcon icon={['fal', 'info-circle']} color='primary' />
          </span>
        </Tooltip>
      ) : null}
    </Stack>
  )
}
