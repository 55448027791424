import { UserAccountControllerApi } from '@rallycry/api-suite-typescript/dist/apis/UserAccountControllerApi'
import { UserAccountCreateCommand } from '@rallycry/api-suite-typescript/dist/models/UserAccountCreateCommand'
import { UserAccountUpdateCommand } from '@rallycry/api-suite-typescript/dist/models/UserAccountUpdateCommand'
import { EntityOptions, useReadEntity } from '../useEntity'
import { useFirebase } from '@/components/providers/site/FirebaseProvider'
import { setUser } from '@/core/google-stackdriver'
import { useRcTranslation } from '@/core/hooks/useRcTranslation'
import { useController } from '@/core/hooks/useSWRApi'

export const useUserAccount = (options?: EntityOptions) => {
  const { user } = useFirebase()
  const { i18n } = useRcTranslation()
  const locale = i18n.language
  const { ctrl } = useController(UserAccountControllerApi)

  const entity = useReadEntity({
    key: 'useUserAccount',
    idOrKey: user?.uid,
    paused: !user || user?.isAnonymous,
    persist: true,
    expand: 'user,organization',
    ...options,
    read: async req => {
      const res = await ctrl({
        expectedStatuses: [401, 404],
        fallbackResponse: null
      }).readUserAccount(req)
      if (res) {
        res.locale = res?.locale?.replace('_', '-')
        setUser(res?.id?.toString())
      }
      return res
    }
  })

  const update = async (_: number, cmd: UserAccountUpdateCommand) => {
    cmd.locale = cmd?.locale?.replace('-', '_')
    const res = await ctrl().updateUserAccount({
      expand: 'user,organization',
      UserAccountUpdateCommand: cmd
    })
    if (res) {
      res.locale = res?.locale?.replace('_', '-')
      setUser(res?.id?.toString())
    }
    await entity.read.mutate(res, { revalidate: false })
    return res
  }

  const create = async (orgId: number, cmd: UserAccountCreateCommand) => {
    const res = await ctrl().createUserAccount({
      UserAccountCreateCommand: {
        ...cmd,
        organization: orgId,
        paymentSubscribed: true,
        competitionSubscribed: true,
        activitySubscribed: true,
        eventReminderSubscribed: true,
        membershipRequestSubscribed: true,
        locale: locale?.replace('-', '_') || 'en'
      }
    })
    await entity.read.mutate()
    return res
  }

  return {
    ...entity,
    create,
    update,
    account: entity.read.data,
    user: entity.read.data?._expanded?.user?.[0]
  }
}
